import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { BLOCKS } from "@contentful/rich-text-types";
import Layout from "../../components/layout/Layout";
import TextCard from "../../components/textCard/TextCard";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import "./simplePage.styles.scss";
import Head from "../../components/head/Head";

const SimplePage = ({ data: { contentfulPageText } }) => {

  const richtextOptions = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        
        const { gatsbyImageData, description } = node.data.target
        return (
          <GatsbyImage
            image={getImage(gatsbyImageData)}
            alt={description}
            width={gatsbyImageData.width}
            height={gatsbyImageData.height}
          />
        )
      },
    }
  }

  return (
    <div className="page__body">
      <Head title={contentfulPageText.title} description={contentfulPageText.metaDescription} robots={contentfulPageText.metaRobots}  />
      <Layout>
        <main className="simple-page">
          <TextCard title={contentfulPageText.title} cardText={renderRichText(contentfulPageText.text, richtextOptions)} />
        </main>
      </Layout>
    </div>
  );
}

export default SimplePage;

export const query = graphql`
query($slug: String) {
  contentfulPageText(slug: {eq: $slug}) {
      title
      metaDescription
      metaRobots
      text {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            title
            description
            gatsbyImageData(width: 1000)
            __typename
          }
        }
      }
  }
}`

